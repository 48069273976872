<template>
  <VContainer class="pa-6" fluid>
    <VRow class="justify-center">
      <VCol cols="10">
        <VCard outlined>
          <VCardTitle class="pt-6 pb-4 px-6">
            <VCol cols="6"> Burndown Chart {{ this.team_name }} </VCol>
            <VCol cols="6">
              <VSelect
                :items="backlogs"
                item-text="name"
                return-object
                v-model="selected_backlog"
                @change="loadBacklog"
                :label="$t('backlog')"
                dense
                outlined
                required
              ></VSelect>
            </VCol>
          </VCardTitle>
          <VCardText class="px-6 py-0">
            <SocioLoader :loading="!displayburndownchart">
              <BurndownChartDisplay
                v-if="displayburndownchart"
                :burndownchart="burndownchart"
                :refreshKey="refreshBurndownKey"
                :key="refreshBurndownKey"
                @storyPointsExcedent="displayStoryPointsExcedent"
                class="pa-4"
              />
            </SocioLoader>
            <BurndownChartSettings
              v-if="displayburndownchart"
              :backlogRecord="selectedBacklogRecord"
              @settingsData="handleSettings"
            />
          </VCardText>
          <VCardActions class="pt-6 pb-4 px-4 d-flex">
            <VRow>
              <VCol cols="8" class="d-flex align-center">
                <VRow class="ma-0" v-if="displayburndownchart">
                  <VSheet
                    v-for="(value, name, index) in display_data"
                    :key="index"
                    class="ma-2"
                  >
                    {{ $t(name) }} : {{ value }}
                  </VSheet>
                  <VAlert
                    v-if="displayburndownchart && storyPointsDontMatch"
                    color="red"
                    type="warning"
                  >
                    {{ this.alertMessage }}
                  </VAlert>
                </VRow>
              </VCol>
              <VCol cols="4" class="justify-end">
                <VBtn
                  color="#005499"
                  dark
                  @click.stop="refreshBurndownChart"
                  class="text-none ma-2"
                >
                  {{ $t("refresh") }}
                </VBtn>
                <VBtn
                  v-if="displayburndownchart && storyPointsDontMatch"
                  color="#005499"
                  dark
                  @click.stop="updateRecord"
                  class="text-none ma-2"
                >
                  {{ $t("updateStoryPoints") }}
                </VBtn>
              </VCol>
            </VRow>
          </VCardActions>
        </VCard>
        <BurndownChartDialog
          :backlog="selected_backlog"
          :dialog="dialog"
          @closedialog="dialog = false"
          @recordData="newRecord"
        />
      </VCol>
    </VRow>
    <VRow class="justify-center">
      <VCol cols="10">
        <VCard outlined>
          <VCardTitle class="pt-6 pb-4 px-6">
            <VCol cols="6"> {{ $t("velocity") }} {{ team_name }} </VCol>
            <VCol cols="6">
              <VSelect
                :items="backlogs"
                item-text="name"
                return-object
                v-model="selected_backlog"
                @change="loadBacklog"
                :label="$t('backlog')"
                dense
                outlined
                required
              ></VSelect>
            </VCol>
            <VCardText class="px-6 py-0">
              <SocioLoader :loading="!displayburndownchart">
                <VelocityBoard :tasks="burndownchart.tasks" />
              </SocioLoader>
            </VCardText>
          </VCardTitle>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="justify-center">
      <VCol cols="10">
        <VCard outlined>
          <VCardTitle class="pt-6 pb-4 px-6">
            <VCol cols="6"> Sprint Zoom </VCol>
            <VCol cols="6" class="pa-0 align-center d-flex">
              <VSelect
                :items="sprints"
                item-text="name"
                return-object
                v-model="selected_sprint"
                @change="drawSprint"
                outlined
                dense
                hide-details
                label="Sprint"
              ></VSelect>
            </VCol>
          </VCardTitle>
          <VCardText class="px-6 py-0">
            <SocioLoader :loading="!displaysprintzoom">
              <SprintZoomDisplay :sprintzoom="sprintzoom" class="pa-4" />
            </SocioLoader>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="justify-center">
      <VCol cols="10">
        <VCard outlined>
          <VCardTitle class="pt-6 pb-4 px-6">
            <VCol cols="6"> Sprint Summary </VCol>
            <VCol cols="6" class="pa-0 align-center d-flex">
              <VSelect
                :items="sprints"
                item-text="name"
                return-object
                v-model="selected_sprint"
                @change="drawSprint"
                outlined
                dense
                hide-details
                label="Sprint"
              ></VSelect>
            </VCol>
          </VCardTitle>
          <VCardText class="px-6 py-0">
            <SocioLoader :loading="!displaysprintzoom">
              <SprintSummaryDisplay
                v-if="displaysprintzoom"
                :sprintzoom="sprintzoom"
                :contributors="sprintzoom.contributors"
                :sprintRecords="sprintRecordsList"
                class="pa-4"
              />
            </SocioLoader>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <VRow class="justify-center">
      <VCol cols="10">
        <VCard outlined>
          <VCardTitle class="pt-6 pb-4 px-6">
            <VCol cols="6"> {{ $t("velocity") }} Sprint </VCol>
            <VCol cols="6">
              <VSelect
                :items="sprints"
                item-text="name"
                return-object
                v-model="selected_sprint"
                @change="drawSprint"
                outlined
                dense
                hide-details
                label="Sprint"
              ></VSelect>
            </VCol>
            <VCardText class="px-6 py-0">
              <SocioLoader :loading="!displaysprintzoom">
                <VelocityBoard :tasks="sprintzoom.tasks" />
              </SocioLoader>
            </VCardText>
          </VCardTitle>
        </VCard>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
import asanaClient from "@/setup/asanaclient";
import dayjs from "dayjs";
import socioAsanaClient from "@/setup/socioAsanaClient";
import { mapGetters, mapActions } from "vuex";
import {
  chronologicallySortProjects,
  isChronologicallyBefore
} from "@/utils/dayjsUtils";
import { useChartMath } from "@/composables/useChartMath";
import BacklogRecord from "@/models/BacklogRecord";
import SprintContributorRecord from "../models/SprintContributorRecord";
import BurndownChartDialog from "@/components/BurndownChartDialog.vue";
import BurndownChartSettings from "@/components/BurndownChartSettings.vue";
import BurndownChartDisplay from "@/components/BurndownChartDisplay.vue";
import SprintZoomDisplay from "@/components/SprintZoomDisplay.vue";
import VelocityBoard from "@/components/VelocityBoard.vue";
import SprintSummaryDisplay from "@/components/SprintSummaryDisplay.vue";

export default {
  name: "TeamDashboard",
  data: () => ({
    dialog: false,
    projects: [],
    displayburndownchart: false,
    burndownchart: {
      tasks: [],
      periods: [],
      startDate: "",
      endDate: "",
      sprintDays: 7
    },
    refreshBurndownKey: 0,
    displaysprintzoom: false,
    sprintzoom: {
      tasks: [],
      startDate: "",
      endDate: "",
      name: ""
    },
    backlogs: [],
    selected_backlog: {
      gid: "",
      name: "",
      start_on: "",
      endDate: ""
    },
    selected_backlog_tasks: [],
    sprints: [],
    selected_sprint: {},
    display_data: {
      theoricVelocity: 0,
      high_estimation: 0,
      low_estimation: 0,
      storyPointsExcedent: 0
    },
    burndownChartTotalStoryPoints: 0,
    settings: {},
    sprintRecords: {}
  }),
  components: {
    BurndownChartDialog,
    BurndownChartDisplay,
    BurndownChartSettings,
    SprintZoomDisplay,
    VelocityBoard,
    SprintSummaryDisplay
  },
  setup() {
    const { computeVelocity, totalStoryPoints } = useChartMath();

    return {
      computeVelocity,
      totalStoryPoints
    };
  },
  computed: {
    ...mapGetters("asanaCredentials", ["getAccessToken"]),
    ...mapGetters("sprintRecord", ["getSprintRecords", "SprintRecordFind"]),
    team_gid() {
      return this.$route.query.gid;
    },
    team_name() {
      return this.$route.query.name;
    },
    backlogRecords() {
      return BacklogRecord.query()
        .where("projectId", this.team_gid)
        .get();
    },
    selectedBacklogRecord() {
      return this.backlogRecords.find(
        backlogRecord => backlogRecord.backlogId === this.selected_backlog.gid
      );
    },
    storyPointsDontMatch() {
      return (
        this.selectedBacklogRecord &&
        this.burndownChartTotalStoryPoints !==
          this.selectedBacklogRecord.totalStoryPoints
      );
    },
    alertMessage() {
      return (
        this.$t("totalStoryPoints") +
        this.burndownChartTotalStoryPoints +
        this.$t("doesnt_match") +
        this.selectedBacklogRecord.totalStoryPoints +
        "."
      );
    },
    selectedSprintRecord() {
      return this.sprintRecords.find(
        sprint => sprint.name === this.sprintzoom.name
      );
    }
  },
  async created() {
    await this.getBacklogRecords();
    this.projects = await this.getProjects();
    this.backlogs = this.projects.filter(project =>
      project.name.toLowerCase().includes("backlog")
    );
    this.selected_backlog = this.backlogs[0];
    await this.loadBacklog();
    this.sprints = this.projects.filter(project =>
      project.name.toLowerCase().includes("sprint")
    );
    this.sprints = chronologicallySortProjects(this.sprints);
    this.selected_sprint = this.sprints[0];
    await this.drawSprint();
    this.loaded = true;
    this.refreshBurndownChart();
  },
  methods: {
    ...mapActions("backlogRecord", [
      "fetchBacklogRecords",
      "createBacklogRecord",
      "updateBacklogRecord"
    ]),
    ...mapActions("sprintRecord", ["fetchSprintRecords", "createSprintRecord"]),
    ...mapActions("sprintContributorRecord", [
      "fetchSprintContributorRecords",
      "createSprintContributorRecord"
    ]),
    async getProjects() {
      const response = await asanaClient.projects.getProjectsForTeam(
        this.team_gid,
        {
          // AC Uncomment this line when developping to get easier to read data. Leave commented when you commit for better performances
          // opt_pretty: true
          opt_fields: ["this.start_on", "this.due_on", "this.name"]
        }
      );
      return response.data;
    },
    async getStories(task_id) {
      const response = await asanaClient.stories.getStoriesForTask(task_id, {
        // AC Uncomment this line when developping to get easier to read data. Leave commented when you commit for better performances
        // opt_pretty: true
        opt_fields: [
          "this.created_at",
          "this.resource_subtype",
          "this.text",
          "this.old_section.name",
          "this.new_section.name"
        ]
      });
      return response.data;
    },
    async getTasks(project_id) {
      let tasks = await socioAsanaClient.getTask(project_id);
      return tasks;
    },
    async getBacklogRecords() {
      try {
        const metadata = {
          filters: JSON.stringify({
            project_id: this.team_gid
          })
        };
        await this.fetchBacklogRecords(metadata);
      } catch (err) {
        console.error(err);
      }
    },
    async createRecord() {
      try {
        const request_data = {
          projectId: this.team_gid,
          backlogId: this.selected_backlog.gid,
          projectName: this.team_name,
          backlogName: this.selected_backlog.name,
          totalStoryPoints: this.burndownChartTotalStoryPoints,
          sprintDuration: this.burndownchart.sprintDays
        };
        await this.createBacklogRecord(request_data);
      } catch (err) {
        console.error(err);
      }
    },
    async updateRecord() {
      try {
        const request_data = {
          uuid: this.selectedBacklogRecord.uuid,
          projectId: this.team_gid,
          backlogId: this.selected_backlog.gid,
          projectName: this.team_name,
          backlogName: this.selected_backlog.name,
          totalStoryPoints: this.burndownChartTotalStoryPoints,
          sprintDuration: this.burndownchart.sprintDays
        };
        await this.updateBacklogRecord(request_data);
        this.storyPointsDontMatch = false;
      } catch (err) {
        console.error(err);
      }
    },
    async newRecord(sprintDays) {
      this.dialog = false;
      this.burndownchart.sprintDays = sprintDays;
      await this.createRecord();
      this.drawBurndownChart();
    },
    async addSprintCompletion(tasks, sprintStartDate) {
      return tasks.map(async task => {
        const {
          currentSectionName,
          stories,
          sprintComplete,
          sprintCompletionDate,
          completedFromStartOfSprint
        } = await this.computeSprintCompletion(task, sprintStartDate);
        return {
          currentSectionName,
          stories: stories,
          sprintComplete,
          sprintCompletionDate,
          completedFromStartOfSprint,
          ...task
        };
      });
    },
    async getSprintList() {
      if (!this.selectedSprintRecord) {
        const request_data = {
          name: this.sprintzoom.name,
          goal: "How we'll collaborate"
        };
        await this.createSprintRecord(request_data);
      }
      return this.sprintRecords;
    },
    async getSprintContributors(tasks) {
      const metadata = {
        filters: JSON.stringify({
          sprint_record: this.selectedSprintRecord.uuid
        })
      };
      await this.fetchSprintContributorRecords(metadata);
      for (let task of tasks) {
        let sprintContributorRecords = SprintContributorRecord.all();
        const complete_name = task.assignee.name.split(" ");
        const first_name = complete_name[0];
        const last_name = complete_name[1];
        const existing = sprintContributorRecords.find(
          contributor => contributor.asanaId === task.assignee.gid
        );
        if (!existing) {
          const request_data = {
            firstName: first_name,
            lastName: last_name,
            asanaId: task.assignee.gid,
            sprintRecord: this.selectedSprintRecord.uuid
          };
          await this.createSprintContributorRecord(request_data);
        }
      }
      return SprintContributorRecord.all();
    },
    getCurrentSectionName(task) {
      let currentSection = task.memberships.find(
        membership =>
          membership.section.project.gid === this.selected_sprint.gid
      );
      return currentSection.section.name;
    },
    async computeSprintCompletion(task, sprintStartDate) {
      const sprintCompletedSections = ["PR To validate", "To validate", "Done"];
      const currentSectionName = this.getCurrentSectionName(task);
      // AC 03/05/22 Eliminating tasks that are not in the appropriate sections and so are not complete.
      if (!sprintCompletedSections.includes(currentSectionName)) {
        return {
          currentSectionName: currentSectionName,
          stories: {},
          sprintComplete: false,
          sprintCompletionDate: "",
          completedFromStartOfSprint: false
        };
      }
      const stories = await this.getStories(task.gid);
      // AC 10/01/22 Stories are objects. The interesting part for this function is their text.
      // Ex : {user_name} moved this Task from "{section_name}" to "{section_name}" in {project_name}
      // Keeping track of the movement in this particular sprint i.e. {project_name} = sprint_name
      // Ex : In sprint 1, task1 was untouched for the whole sprint.
      //      In Sprint 2, task1 was moved to PR to Validate on a certain day.
      // When displaying sprint 1, we need to eliminate the stories of other sprints
      // or this task will be considered complete in sprint 1 when it wasn't.
      // Asana tasks can be shared between projects (Backlog, Sprint etc..)
      const sprintSectionChanges = stories.filter(
        story =>
          story.resource_subtype == "section_changed" &&
          story.text.split(" in ")[1] === this.sprintzoom.name
      );
      // if sprintSectionChanges is empty then the task hasn't been moved and the task was put in this sprint completed
      if (!sprintSectionChanges.length) {
        return {
          currentSectionName: currentSectionName,
          stories: stories,
          sprintComplete: true,
          sprintCompletionDate: "",
          completedFromStartOfSprint: true
        };
      }
      // Finding when task was first put in a completed Section from a nonCompleted Section and stayed in one.
      // sprintSectionChanges are chronologically ordered from oldest to newest.
      let sprintCompletionSectionChange = {};
      sprintSectionChanges.forEach(sectionChange => {
        // This sectionChange put task in a completed Section from a nonCompletedSection
        if (
          sprintCompletedSections.includes(sectionChange.new_section.name) &&
          !sprintCompletedSections.includes(sectionChange.old_section.name)
        ) {
          sprintCompletionSectionChange = sectionChange;
        } else if (
          !sprintCompletedSections.includes(sectionChange.new_section.name)
        ) {
          // This sectionchange put task in a non-completed Section so it's not complete anymore at the time.
          sprintCompletionSectionChange = {};
        }
      });
      // If sprintCompletionSectionChange === {} then task was moved from completedSections to other completedSections
      // Ex: From 'PR to Validate' to 'to Validate' so the task was completed at the start of the sprint
      // If sprintCompletionDate is the same as sprintStartDate then the task was most likely added to sprint completed
      return {
        currentSectionName: currentSectionName,
        stories: stories,
        sprintComplete: true,
        sprintCompletionDate:
          Object.keys(sprintCompletionSectionChange).length != 0
            ? sprintCompletionSectionChange.created_at
            : "",
        completedFromStartOfSprint:
          Object.keys(sprintCompletionSectionChange).length === 0 ||
          dayjs(sprintCompletionSectionChange.created_at).isSameOrBefore(
            sprintStartDate,
            "day"
          )
      };
    },
    async loadBacklogTasks() {
      this.selected_backlog_tasks = await this.getTasks(
        this.selected_backlog.gid
      );
    },
    filterTasks(tasks) {
      if (this.settings.selected_prio) {
        return tasks.filter(
          task => this.settings.selected_prio.includes(task.prio) || !task.prio
        );
      }
      return tasks;
    },
    async loadBacklog() {
      this.displayburndownchart = false;
      await this.loadBacklogTasks();
      this.computeTotalStoryPoints();
      this.drawBurndownChart();
    },
    refreshBurndownChart() {
      this.drawBurndownChart();
      this.refreshBurndownKey += 1;
    },
    drawBurndownChart() {
      this.displayburndownchart = false;
      if (!this.selectedBacklogRecord) {
        this.dialog = true;
        return;
      }
      this.burndownchart.tasks = this.filterTasks(this.selected_backlog_tasks);
      this.burndownchart.periods = this.settings.periods;
      this.burndownchart.startDate = this.selected_backlog.start_on;
      this.burndownchart.endDate = this.selected_backlog.due_on;
      this.burndownchart.sprintDays = this.selectedBacklogRecord.sprintDuration;
      this.displayburndownchart = true;
      this.displayTheoricVelocity();
      this.displayEstimations();
    },
    async drawSprint() {
      this.displaysprintzoom = false;
      let tasks = await this.getTasks(this.selected_sprint.gid);
      this.sprintzoom.startDate = this.selected_sprint.start_on;
      this.sprintzoom.endDate = this.selected_sprint.due_on;
      this.sprintzoom.name = this.selected_sprint.name;
      this.sprintzoom.gid = this.selected_sprint.gid;
      this.sprintzoom.tasks = await Promise.all(
        await this.addSprintCompletion(tasks, this.sprintzoom.startDate)
      );
      this.sprintRecords = await this.getSprintRecords;
      this.sprintRecordsList = await this.getSprintList();
      this.sprintRecords = await this.getSprintRecords;
      this.sprintzoom.contributors = await this.getSprintContributors(
        this.sprintzoom.tasks
      );
      this.displaysprintzoom = true;
    },
    displayTheoricVelocity() {
      this.display_data.theoricVelocity = Math.round(
        this.computeVelocity(
          this.burndownchart.tasks,
          this.burndownchart.startDate,
          this.burndownchart.endDate,
          this.burndownchart.sprintDays
        )
      );
    },
    displayEstimations() {
      this.display_data.high_estimation = this.burndownchart.tasks.reduce(
        (acc, task) => {
          if (
            isChronologicallyBefore(
              task.created_at,
              this.burndownchart.startDate
            )
          ) {
            acc += task.high_estimation;
          }
          return acc;
        },
        0
      );
      this.display_data.low_estimation = this.burndownchart.tasks.reduce(
        (acc, task) => {
          if (
            isChronologicallyBefore(
              task.created_at,
              this.burndownchart.startDate
            )
          ) {
            acc += task.low_estimation;
          }
          return acc;
        },
        0
      );
    },
    displayStoryPointsExcedent(storyPointsExcedent) {
      if (storyPointsExcedent < 0) {
        this.display_data.storyPointsExcedent = -storyPointsExcedent;
      }
    },
    computeTotalStoryPoints() {
      this.burndownChartTotalStoryPoints = this.totalStoryPoints(
        this.selected_backlog_tasks,
        this.selected_backlog.start_on
      );
    },
    handleSettings(settings) {
      this.settings = settings;
    }
  }
};
</script>

<i18n>
{
  "fr": {
    "projectName": "Nom du projet",
    "backlog": "Backlog",
    "refresh" : "Actualiser BurnChart",
    "theoricVelocity": "Vélocité théorique",
    "velocity" : "Vélocité",
    "high_estimation" : "Estimation haute",
    "low_estimation" : "Estimation basse",
    "totalStoryPoints" : "Le total de story points : ",
    "doesnt_match": " ne corresponds pas à l'ancienne valeur : ",
    "updateStoryPoints": "Mettre à jour valeur",
    "storyPointsExcedent": "StoryPoints Bonus"
  },
  "en": {
    "projectName": "Project name",
    "backlog": "Backlog",
    "refresh" : "Refresh BurndownChart",
    "theoricVelocity": "Theoric Velocity",
    "velocity" : "Velocity",
    "high_estimation" : "High estimation",
    "low_estimation" : "Low estimation",
    "totalStoryPoints" : "Story Points Total : ",
    "doesnt_match": "doesn't match with the old value : ",
    "updateStoryPoints": "New story points Value",
    "storyPointsExcedent": "StoryPoints Bonus"
  }
}
</i18n>
