<template>
  <VContainer>
    <SocioWysiwyg
      :value="selectedSprint.goal"
      label="Objectifs du sprint"
      @input="updateSprintGoal($event)"
    >
    </SocioWysiwyg>
    <VRow no-gutters justify="space-around">
      <VCard
        class="ma-6 pa-6"
        width="443"
        v-for="contributor in contributors"
        :key="contributor.uuid"
      >
        <VRow class="d-flex flex-row pb-6 align-center" justify="space-between">
          <VCol class="d-flex flex-row align-center" justify="space-around">
            <SocioBaseInitials
              style="vertical-align: middle;"
              :firstName="contributor.firstName"
              :lastName="contributor.lastName"
              size="50"
            />
            <VCardTitle
              >{{ contributor.firstName }}
              {{ contributor.lastName }}</VCardTitle
            >
          </VCol>
          <VCol cols="4">
            <VSelect
              :items="percentages"
              :value="getPercentage(contributor)"
              outlined
              dense
              hide-details
              label="Présence"
              @input="updatePercentage($event, contributor)"
            >
            </VSelect>
          </VCol>
        </VRow>
        <VTextarea
          :value="contributor.feeling"
          outlined
          auto-grow
          label="Feeling"
          @change="updateFeeling($event, contributor)"
        >
        </VTextarea>
      </VCard>
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SprintSummaryDisplay",
  components: {},
  props: {
    sprintzoom: {
      type: Object,
      required: true
    },
    contributors: {
      type: Array,
      required: true
    },
    sprintRecords: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sprintContributors: [],
      selectedSprint: []
    };
  },
  methods: {
    ...mapActions("sprintRecord", ["updateSprintRecord"]),
    ...mapActions("sprintContributorRecord", ["updateSprintContributorRecord"]),
    updatePercentage(value, contributor) {
      let matches = value.match(/(\d+)/);
      let percentages = matches[0];
      const request_data = {
        ...contributor,
        presencePercentage: percentages
      };
      this.updateSprintContributorRecord(request_data);
    },
    getPercentage(contributor) {
      let percentage = contributor.presencePercentage + "%";
      return percentage;
    },
    updateFeeling(value, contributor) {
      const request_data = {
        ...contributor,
        feeling: value
      };
      this.updateSprintContributorRecord(request_data);
    },
    async updateSprintGoal(value) {
      const request_data = {
        ...this.selectedSprint,
        goal: value
      };

      this.updateSprintRecord(request_data);
    },
    async getSelectedSprint() {
      const sprints = this.sprintRecords;

      for (let sprint of sprints) {
        if (sprint.name == this.sprintzoom.name) {
          this.selectedSprint = sprint;
        }
      }
    },
    createPercentageArray() {
      let percentages = [];
      let i = 0;

      while (i <= 100) {
        percentages.push(i + "%");
        i = i + 10;
      }
      return percentages;
    }
  },
  computed: {
    percentages: function() {
      return this.createPercentageArray();
    }
  },
  async created() {
    await this.getSelectedSprint();
  }
};
</script>
<i18n>
{
  "fr": {
    "averageVelocity": "Vélocité moyenne",
    "firstName": "Prénom",
    "lastName" : "Nom",
    "doneStoryPoints" : "SP Faits",
    "impediments" : "Impediments"
  },
  "en": {
    "averageVelocity": "Average velocity",
    "assignee": "Assignee",
    "initialStoryPoints" : "Initial SP",
    "doneStoryPoints" : "Done SP",
    "impediments" : "Impediments"
  }
}
</i18n>
