<template>
  <Chart
    v-if="loaded"
    :chartType="chartType"
    :chartData="chartData"
    :chartOptions="chartOptions"
  ></Chart>
</template>
<script>
import Chart from "@/components/Chart.vue";
import dayjs from "dayjs";
import { useChartMath } from "@/composables/useChartMath";

export default {
  name: "SprintZoomDisplay",
  components: {
    Chart
  },
  props: {
    sprintzoom: {
      type: Object,
      required: true,
      default: () => {
        return {
          tasks: [],
          startDate: "",
          endDate: "",
          name: ""
        };
      }
    }
  },
  data() {
    return {
      chartType: "line",
      chartData: {
        datasets: []
      },
      chartOptions: {
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
              isoWeekday: true,
              displayFormats: {
                week: "D MMM"
              }
            }
          },
          y: {
            title: {
              display: true,
              text: "Story Points"
            }
          }
        }
      },
      loaded: false,
      initial_tasks: [],
      tasks: []
    };
  },
  setup() {
    const { estimationSprintChart, progressionChart } = useChartMath();
    return {
      estimationSprintChart,
      progressionChart
    };
  },
  created() {
    this.generateSprintZoom();
  },
  methods: {
    generateSprintZoom() {
      this.initial_tasks = this.sprintzoom.tasks.filter(task =>
        dayjs(task.created_at).isSameOrBefore(this.sprintzoom.startDate, "day")
      );
      let estimationSprintChart = this.estimationSprintChart(
        this.sprintzoom.tasks,
        this.sprintzoom.startDate,
        this.sprintzoom.endDate,
        "Estimation initiale",
        "rgb(0, 130, 222)"
      );
      this.chartData.datasets.push(estimationSprintChart);
      this.chartData.datasets.push(
        this.progressionChart(
          this.initial_tasks,
          this.sprintzoom.startDate,
          this.sprintzoom.endDate,
          1,
          "Progression initiale",
          "rgb(169, 196, 9)",
          false
        )
      );
      this.chartData.datasets.push(
        this.progressionChart(
          this.sprintzoom.tasks,
          this.sprintzoom.startDate,
          this.sprintzoom.endDate,
          1,
          "Progression dynamique",
          "rgb(255, 101, 51)",
          false
        )
      );
      this.loaded = true;
    }
  }
};
</script>
