import dayjs from "dayjs";
export function useChartMath() {
  /**Construct an estimation chart for BurndownChart
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {String} startDate : string representing the startDate
   * @param {String} endDate : string representing the endDate
   * @param {Number} sprintDays : Number of days in a sprint
   * @param {String} name : name of the chart
   * @param {String} color : string representing the chart color
   * @param {Object Array} periods : array of periods to compute estimation
   * @returns A dataset for Chart.js containing an Estimation Chart
   */
  const estimationChart = (
    tasks,
    startDate,
    endDate,
    name,
    color,
    sprintDays = 0,
    periods = []
  ) => {
    let chart = {
      label: name,
      borderColor: color,
      backgroundColor: "rgb(0,0,0,0)",
      data: []
    };
    const intialProjectionPoint = createFirstInitialProjectionPoint(
      tasks,
      dayjs(startDate)
    );
    const theoricVelocity = computeVelocity(
      tasks,
      startDate,
      endDate,
      sprintDays
    );
    let projectedStoryPoints = intialProjectionPoint.y;
    if (periods.length == 0) {
      chart.data = [intialProjectionPoint, { x: dayjs(endDate), y: 0 }];
    } else {
      chart.data.push(intialProjectionPoint);
      let currentPeriodIndex = 0;
      let currentVelocity = 0;
      let date = dayjs(startDate);
      let nextDate = date.add(sprintDays, "day");
      let lastPoint = {};
      while (nextDate.isSameOrBefore(endDate, "day")) {
        [
          currentVelocity,
          currentPeriodIndex
        ] = computeCurrentVelocityFromPeriods(
          nextDate,
          periods,
          currentPeriodIndex
        );
        if (
          projectedStoryPoints < currentVelocity &&
          projectedStoryPoints > 0
        ) {
          const endPoint = computeRealEndDate(
            projectedStoryPoints,
            date,
            sprintDays,
            currentVelocity
          );
          chart.data.push(endPoint);
        }
        projectedStoryPoints -= currentVelocity;
        lastPoint = {
          x: nextDate.format("YYYY-MM-DD"),
          y: projectedStoryPoints > 0 ? projectedStoryPoints : 0
        };
        chart.data.push(lastPoint);
        date = date.add(sprintDays, "day");
        nextDate = date.add(sprintDays, "day");
      }
      if (lastPoint.y > 0) {
        chart.data.push(
          pushCloseToZero(lastPoint, sprintDays, theoricVelocity)
        );
      }
    }
    return [chart, projectedStoryPoints];
  };
  /**Construct an estimation chart for SprintZoom
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {String} startDate : string representing the startDate
   * @param {String} endDate : string representing the endDate
   * @param {String} name : name of the chart
   * @param {String} color : string representing the chart color
   * @returns A dataset for Chart.js containing an Estimation Chart
   */
  const estimationSprintChart = (tasks, startDate, endDate, name, color) => {
    let chart = {
      label: name,
      borderColor: color,
      backgroundColor: "rgb(0,0,0,0)",
      data: []
    };
    const intialProjectionPoint = createFirstSprintProjectionPoint(
      tasks,
      dayjs(startDate)
    );
    chart.data = [intialProjectionPoint, { x: dayjs(endDate), y: 0 }];
    return chart;
  };
  /**Construct a progression chart for BurndownChart and Sprintzoom
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {String} startDate : string representing the startDate
   * @param {String} endDate : string representing the endDate
   * @param {Number} sprintDays : Number of days in a sprint
   * @param {String} name : name of the chart
   * @param {String} color : string representing the chart color
   * @param {Boolean} addToday : boolean if today's point is needed
   * @returns A dataset for Chart.js containing a Progression Chart
   */
  const progressionChart = (
    tasks,
    startDate,
    endDate,
    sprintDays,
    name,
    color,
    addToday
  ) => {
    let dataset = {
      label: name,
      borderColor: color,
      backgroundColor: "rgb(0,0,0,0)",
      data: []
    };
    let date = dayjs(startDate);
    let now = dayjs();

    while (
      date.isSameOrBefore(endDate, "day") &&
      date.isSameOrBefore(now, "day")
    ) {
      dataset.data.push(createProgressionPoint(tasks, date));
      date = date.add(sprintDays, "day");
    }
    if (addToday) {
      dataset.data.push(createProgressionPoint(tasks, now));
    }
    return dataset;
  };
  /**Construct a dynamic estimation chart for BurnDownChart
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {String} startDate : string representing the startDate
   * @param {String} endDate : string representing the endDate
   * @param {Number} sprintDays : Number of days in a sprint
   * @param {String} name : name of the chart
   * @param {String} color : string representing the chart color
   * @param {Object Array} periods : array of periods to compute estimation
   * @returns A dataset for Chart.js containing a Dynamic Estimation Chart
   */

  const dynamicEstimationChart = (
    tasks,
    startDate,
    endDate,
    sprintDays,
    name,
    color,
    periods = []
  ) => {
    let dataset = {
      label: name,
      borderColor: color,
      backgroundColor: "rgb(0,0,0,0)",
      data: []
    };
    // Initial Point
    const startDateObject = dayjs(startDate);
    const endDateObject = dayjs(endDate);
    const initialProjection = createFirstUpdatedProjectionPoint(
      tasks,
      startDateObject
    );
    dataset.data.push(initialProjection);

    // Variables initialization

    let date = startDateObject;
    let nextDate = date.add(sprintDays, "day");
    const now = dayjs();
    const theoricVelocity = computeVelocity(
      tasks,
      startDate,
      endDate,
      sprintDays
    );
    const usePeriods = periods.length != 0;
    let lastPoint = initialProjection;
    let currentPeriodIndex = 0;
    let currentVelocity = theoricVelocity;
    let currentStoryPoints = lastPoint.y;

    // Build points two by two : Projection through velocity and the projection impacted with tasks until enddate.
    // Stopping at today if periods are not used.
    while (
      nextDate.isSameOrBefore(endDateObject, "day") &&
      (usePeriods || nextDate.isSameOrBefore(now, "day"))
    ) {
      if (usePeriods) {
        [
          currentVelocity,
          currentPeriodIndex
        ] = computeCurrentVelocityFromPeriods(
          nextDate,
          periods,
          currentPeriodIndex
        );
      }
      if (lastPoint.y < currentVelocity && lastPoint.y > 0) {
        const endPoint = computeRealEndDate(
          lastPoint.y,
          date,
          sprintDays,
          currentVelocity
        );
        dataset.data.push(endPoint);
      }
      currentStoryPoints = lastPoint.y - currentVelocity;
      let nextProjection = {
        x: nextDate.format("YYYY-MM-DD"),
        y: currentStoryPoints > 0 ? currentStoryPoints : 0
      };
      dataset.data.push(nextProjection);
      let storyPointsCreated = getStoryPointsCreated(tasks, date, nextDate);
      let projectionWithAddedTasks = {
        x: nextDate.format("YYYY-MM-DD"),
        y: nextProjection.y + storyPointsCreated
      };
      if (storyPointsCreated != 0) {
        dataset.data.push(projectionWithAddedTasks);
      }
      date = nextDate;
      nextDate = nextDate.add(sprintDays, "day");
      lastPoint = projectionWithAddedTasks;
    }
    // Build last two points for today if not using periods
    if (!usePeriods) {
      const daysLeft = now.diff(date, "day");
      let nextProjection = {
        x: now.format("YYYY-MM-DD"),
        y: lastPoint.y - daysLeft * (theoricVelocity / sprintDays)
      };
      if (nextProjection.y >= 0) {
        dataset.data.push(nextProjection);

        let storyPointsCreated = getStoryPointsCreated(tasks, date, now);
        let projectionWithAddedTasks = {
          x: now.format("YYYY-MM-DD"),
          y: nextProjection.y + storyPointsCreated
        };
        if (storyPointsCreated != 0) {
          dataset.data.push(projectionWithAddedTasks);
        }
        lastPoint = projectionWithAddedTasks;
      }
    }
    // Follow velocity till it would cross 0 once with sprintDays
    if (lastPoint.y > 0) {
      dataset.data.push(
        pushCloseToZero(lastPoint, sprintDays, theoricVelocity)
      );
    }

    return dataset;
  };

  /**Create a point with total initial story points of all tasks
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {Object} date DayjsObject representing a date
   * @returns a point for Chart.js with asked coordinates
   */
  const createFirstInitialProjectionPoint = (tasks, date) => {
    const totalInitialStorypoint = totalInitialStorypoints(tasks);
    return { x: date.format("YYYY-MM-DD"), y: totalInitialStorypoint };
  };

  /**Create a point with total story points of all tasks created before date
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {Object} date DayjsObject representing a date
   * @returns a point for Chart.js with asked coordinates
   */
  const createFirstUpdatedProjectionPoint = (tasks, date) => {
    const totalStoryPoint = totalStoryPoints(tasks, date);
    return { x: date.format("YYYY-MM-DD"), y: totalStoryPoint };
  };

  /**Create a point with total story points of all tasks created before date eliminating tasks completed from the start
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {Object} date DayjsObject representing a date
   * @returns a point for Chart.js with asked coordinates
   */
  const createFirstSprintProjectionPoint = (tasks, date) => {
    const totalSprintStoryPoint = tasks.reduce((acc, task) => {
      const isTaskCreatedBeforeParamDate = dayjs(
        task.created_at
      ).isSameOrBefore(date, "day");
      const hasTaskBeenDecommissionedBeforeParamDate =
        task.decommission_date &&
        dayjs(task.decommission_date).isSameOrBefore(date, "day");
      const hasTaskBeenCompletedBeforeSprint = task.completedFromStartOfSprint;
      if (
        isTaskCreatedBeforeParamDate &&
        !hasTaskBeenDecommissionedBeforeParamDate &&
        !hasTaskBeenCompletedBeforeSprint
      ) {
        return acc + task.storypoint;
      }
      return acc;
    }, 0);
    return { x: date.format("YYYY-MM-DD"), y: totalSprintStoryPoint };
  };
  /**Create a point with Total story points of tasks created before date and not yet completed at the time
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {Object} date DayjsObject representing a date
   * @returns A point for a progression chart
   */
  const createProgressionPoint = (tasks, date) => {
    const totalPendingStoryPoint = tasks.reduce((acc, task) => {
      const isTaskCreatedBeforeParamDate = dayjs(
        task.created_at
      ).isSameOrBefore(date, "day");
      const isTaskCompletedAfterParamDate = dayjs(task.completed_at).isAfter(
        date,
        "day"
      );
      const hasTaskBeenDecommissionedBeforeParamDate =
        task.decommission_date &&
        dayjs(task.decommission_date).isSameOrBefore(date, "day");
      const isTaskSprintComplete =
        (task.sprintComplete &&
          dayjs(task.sprintCompletionDate).isSameOrBefore(date, "day")) ||
        task.completedFromStartOfSprint;
      if (
        isTaskCreatedBeforeParamDate &&
        (!task.completed || isTaskCompletedAfterParamDate) &&
        !isTaskSprintComplete &&
        !hasTaskBeenDecommissionedBeforeParamDate
      ) {
        return acc + task.storypoint;
      }
      return acc;
    }, 0);
    return { x: date.format("YYYY-MM-DD"), y: totalPendingStoryPoint };
  };
  /**Get Total story points of tasks created between date1 and date 2.
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {Object} startDate : DayjsObject representing the startDate
   * @param {Object} endDate : DayjsObject representing the endDate
   * @returns {Number} Number of storypoints created in the duration.
   */
  const getStoryPointsCreated = (tasks, startDate, endDate) => {
    const storyPointsCreated = tasks.reduce((acc, task) => {
      if (
        dayjs(task.created_at).isAfter(startDate, "day") &&
        dayjs(task.created_at).isSameOrBefore(endDate, "day")
      ) {
        return acc + task.storypoint;
      }
      if (
        task.decommission_date &&
        dayjs(task.decommission_date).isAfter(startDate, "day") &&
        dayjs(task.decommission_date).isSameOrBefore(endDate, "day")
      ) {
        return acc - task.storypoint;
      }
      return acc;
    }, 0);
    return storyPointsCreated;
  };
  /**Using an initial point , sprintDays and a velocity. Return the closest point to 0 storypoints left
   *
   * @param {Object} initialPoint : First point to construct the graph.
   * @param {Number} sprintDays : Number of days in a sprint
   * @param {Number} velocity : Velocity for a sprint
   * @returns {Object Array} : A point to close the chart gracefully.
   */
  const pushCloseToZero = (initialPoint, sprintDays, velocity) => {
    let storyPointsLeft = initialPoint.y;
    let date = dayjs(initialPoint.x);

    const nbSprintsNeeded = Math.trunc(storyPointsLeft / velocity);
    date = date.add({ days: nbSprintsNeeded * sprintDays });

    storyPointsLeft = storyPointsLeft % velocity;
    const dailyVelocity = velocity / sprintDays;
    const nbDaysNeeded = Math.trunc(storyPointsLeft / dailyVelocity);

    date = date.add({ days: nbDaysNeeded });
    storyPointsLeft = storyPointsLeft % dailyVelocity;

    return { x: date.format("YYYY-MM-DD"), y: storyPointsLeft };
  };
  /** Accumulate all initial story points of tasks
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @returns  {Number}
   */
  const totalInitialStorypoints = tasks => {
    const totalStoryPoint = tasks.reduce((acc, task) => {
      acc += task.storypoint_inital;
      return acc;
    }, 0);
    return totalStoryPoint;
  };
  /** Accumulate all story points of tasks created before date.
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {Object/String} date DayjsObject or string representing a date
   * @returns  {Number}
   */
  const totalStoryPoints = (tasks, date) => {
    const totalStoryPoint = tasks.reduce((acc, task) => {
      if (dayjs(task.created_at).isSameOrBefore(date, "day")) {
        acc += task.storypoint;
      }
      return acc;
    }, 0);
    return totalStoryPoint;
  };
  /**Computes velocity with Thales Theorem from chart data
   *
   * @param {Object Array} tasks: tasks used to create chart
   * @param {String} startDate : string representing the startDate
   * @param {String} endDate : string representing the endDate
   * @param {Number} sprintDays : Number of days in a sprint
   * @returns {Number}
   */
  const computeVelocity = (tasks, startDate, endDate, sprintDays) => {
    const startDateObject = dayjs(startDate);
    const endDateObject = dayjs(endDate);
    const totalStoryPoint = totalStoryPoints(tasks, startDateObject);
    const nextDate = startDateObject.add(sprintDays, "day");
    const velocity =
      totalStoryPoint *
      (1 -
        nextDate.diff(endDate, "day", true) /
          startDateObject.diff(endDateObject, "day", true));
    return velocity;
  };

  const computeCurrentVelocityFromPeriods = (
    date,
    periods,
    currentPeriodIndex
  ) => {
    let currentVelocity = 0;
    // Is date in the current Period ?
    if (
      date.isBetween(
        periods[currentPeriodIndex].startDate,
        periods[currentPeriodIndex].endDate,
        "day",
        "[]"
      )
    ) {
      currentVelocity = periods[currentPeriodIndex].velocity;
    } else {
      // Is date in a gap without Period or the next ?
      if (
        currentPeriodIndex !== periods.length - 1 &&
        date.isAfter(periods[currentPeriodIndex + 1].startDate, "day")
      ) {
        // It is in the next
        currentPeriodIndex += 1;
      }
      if (date.isBefore(periods[currentPeriodIndex].endDate, "day")) {
        // We verified it is indeed in the Period
        currentVelocity = periods[currentPeriodIndex].velocity;
      }
    }
    return [currentVelocity, currentPeriodIndex];
  };
  /**Computes real end date with storyPointsLeft < currentVelocity.
   *
   * @param {Object Array} storyPointsLeft: story points left to do
   * @param {Object} date : Date Object representing the date
   * @param {Number} sprintDays : Number of days in a sprint
   * @param {Number} velocity : Velocity for a sprint
   * @returns {Object} endPoint of the project
   */
  const computeRealEndDate = (storyPointsLeft, date, sprintDays, velocity) => {
    const dailyVelocity = velocity / sprintDays;
    const nbDaysNeeded = Math.trunc(storyPointsLeft / dailyVelocity) + 1;
    return { x: date.add({ days: nbDaysNeeded }).format("YYYY-MM-DD"), y: 0 };
  };
  return {
    createProgressionPoint,
    createFirstInitialProjectionPoint,
    createFirstUpdatedProjectionPoint,
    createFirstSprintProjectionPoint,
    getStoryPointsCreated,
    pushCloseToZero,
    estimationChart,
    estimationSprintChart,
    progressionChart,
    dynamicEstimationChart,
    totalStoryPoints,
    computeVelocity,
    computeRealEndDate
  };
}
