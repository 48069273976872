import dayjs from "dayjs";

const dateNow = dayjs();

/** Checks is first_date is before second_date chronologically.
 *
 * @param {String} first_date
 * @param {String} second_date
 * @returns {Boolean}
 */
function isChronologicallyBefore(first_date, second_date) {
  return dayjs(first_date).isSameOrBefore(second_date, "day");
}

/** Checks is first_date is after second_date chronologically.
 *
 * @param {String} first_date
 * @param {String} second_date
 * @returns {Boolean}
 */
function isChronologicallyAfter(first_date, second_date) {
  return dayjs(first_date).isSameOrAfter(second_date, "day");
}

/** Checks if date is between date_before and date_after
 *
 * @param {String} date
 * @param {String} date_before
 * @param {String} date_after
 * @returns {Boolean}
 */
function isChronologicallyBetween(date, date_before, date_after) {
  return dayjs(date).isBetween(date_before, date_after);
}

// Reverse chronologically sort Projects. The one starting the more recently is first.
function chronologicallySortProjects(projects) {
  return projects.sort((first_project, second_project) => {
    if (
      isChronologicallyAfter(first_project.start_on, second_project.start_on)
    ) {
      return -1;
    } else {
      return 1;
    }
  });
}

// Chronologically sort Periods according to startDate.
function chronologicallySortPeriods(periods) {
  return periods.sort((first_period, second_period) => {
    if (
      isChronologicallyBefore(first_period.startDate, second_period.startDate)
    ) {
      return -1;
    } else {
      return 1;
    }
  });
}

function formatDate(string, format) {
  return dayjs(string).format(format);
}

export {
  dateNow,
  isChronologicallyBefore,
  isChronologicallyAfter,
  isChronologicallyBetween,
  chronologicallySortProjects,
  chronologicallySortPeriods,
  formatDate
};
