<template>
  <VCard outlined>
    <VCardTitle>
      {{ $t("settings") }}
    </VCardTitle>
    <VCardText>
      <h4>{{ $t("priority") }}</h4>
      <VRow class="px-4">
        <VCheckbox
          v-model="settings.selected_prio"
          v-for="option in prios_options"
          @change="settingsData"
          :key="option.name"
          :label="option.name"
          :value="option.name"
          :color="option.color"
          class="px-1"
        />
      </VRow>
      <h4>Périodes</h4>
      <VCard outlined>
        <VResponsive aspect-ratio="15">
          <VChip
            v-for="(period, index) in settings.periods"
            :key="index"
            color="blue"
            label
            dark
            class="ma-1"
          >
            {{ periodLabel(period) }}
            <VBtn icon dense @click="deleteSettingsPeriod(index)">
              <VIcon>mdi-close-circle</VIcon>
            </VBtn>
          </VChip>
        </VResponsive>
      </VCard>
      <VRow class="ma-0">
        <VCol cols="3">
          <SocioBaseInputDate
            :dateLabel="$t('startDate')"
            v-model="period.startDate"
          />
        </VCol>
        <VCol cols="3">
          <SocioBaseInputDate
            :dateLabel="$t('endDate')"
            v-model="period.endDate"
          />
        </VCol>
        <VCol cols="3">
          <VTextField
            :label="$t('velocity')"
            outlined
            v-model="period.velocity"
            type="Number"
          >
          </VTextField>
        </VCol>
        <VCol cols="3">
          <VBtn color="blue darken-1" outlined @click="addPeriod" class="mt-2">
            {{ $t("createPeriod") }}
          </VBtn>
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>
<script>
import { formatDate } from "@/utils/dayjsUtils";
import Period from "@/models/Period";
import { chronologicallySortPeriods } from "@/utils/dayjsUtils";

export default {
  name: "BurndownChartSettings",
  props: {
    backlogRecord: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    menu: false,
    prios_options: [
      {
        name: "P0",
        color: "red"
      },
      {
        name: "P1",
        color: "orange darken-3"
      },
      {
        name: "P2",
        color: "orange"
      },
      {
        name: "P3",
        color: "yellow"
      },
      {
        name: "P4",
        color: "green"
      },
      {
        name: "P5",
        color: "green darken-2"
      }
    ],
    settings: {
      selected_prio: ["P0", "P1", "P2", "P3", "P4", "P5"],
      periods: []
    },
    period: {
      startDate: "",
      endDate: "",
      velocity: 0
    }
  }),
  computed: {
    periods() {
      return Period.query()
        .where("backlogrecord", this.backlogRecord.uuid)
        .get();
    }
  },
  async mounted() {
    await this.refreshPeriods();
  },
  methods: {
    resetPeriod() {
      this.period = {
        startDate: "",
        endDate: "",
        velocity: 0
      };
    },
    async getPeriods() {
      const filters = {
        backlogrecord: this.backlogRecord.uuid
      };
      await Period.fetchPeriods(filters);
    },
    async newPeriod() {
      const request_data = {
        backlogrecord: this.backlogRecord.uuid,
        startDate: this.period.startDate,
        endDate: this.period.endDate,
        velocity: this.period.velocity
      };
      await Period.createPeriod(request_data);
    },
    async refreshPeriods() {
      await this.getPeriods();
      this.settings.periods = chronologicallySortPeriods(this.periods);
      this.settingsData();
    },
    settingsData() {
      this.$emit("settingsData", this.settings);
    },
    async addPeriod() {
      await this.newPeriod();
      await this.refreshPeriods();
      this.resetPeriod();
    },
    async deleteSettingsPeriod(index) {
      let periodToDelete = this.settings.periods[index];
      const periodUuid = periodToDelete.uuid;
      await Period.deletePeriod(periodUuid);
      await this.refreshPeriods();
    },
    periodLabel(period) {
      return (
        formatDate(period.startDate, "DD/MM") +
        " -- V: " +
        String(period.velocity) +
        " -- " +
        formatDate(period.endDate, "DD/MM")
      );
    }
  }
};
</script>

<i18n>
{
  "fr": {
    "locale": "fr",
    "settings": "Paramètres",
    "startDate" : "Début",
    "endDate" : "Fin",
    "velocity" : "Vélocité",
    "createPeriod": "Créer une période",
    "priority" : "Priorité"
  },
  "en": {
    "locale": "en",
    "settings": "Settings",
    "startDate" : "Start Date",
    "endDate" : "End Date",
    "velocity" : "Velocity",
    "createPeriod": "Create period",
    "priority" : "Priority"
  }
}
</i18n>
