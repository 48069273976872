<template>
  <canvas ref="chartCanvas"></canvas>
</template>
<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export default {
  name: "Chart",
  props: {
    chartType: {
      type: String,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    },
    refreshKey: {
      type: Number
    }
  },
  computed: {
    canvas() {
      return this.$refs.chartCanvas;
    }
  },
  mounted() {
    let ctx = this.canvas.getContext("2d");
    this.chartObject = new Chart(ctx, {
      type: this.chartType,
      data: this.chartData,
      options: this.chartOptions
    });
  }
};
</script>
