<template>
  <Chart
    v-if="loaded"
    :chartType="chartType"
    :chartData="chartData"
    :chartOptions="chartOptions"
    :refreshKey="refreshKey"
  ></Chart>
</template>
<script>
import Chart from "@/components/Chart.vue";
import { useChartMath } from "@/composables/useChartMath";
import { dateNow, isChronologicallyBetween } from "@/utils/dayjsUtils";

export default {
  name: "BurndownChartDisplay",
  components: {
    Chart
  },
  props: {
    burndownchart: {
      type: Object,
      required: true,
      default: () => {
        return {
          tasks: [],
          periods: [],
          startDate: "",
          endDate: "",
          sprintDays: 7
        };
      }
    },
    refreshKey: {
      type: Number
    }
  },
  data() {
    return {
      chartType: "line",
      chartData: {
        datasets: []
      },
      chartOptions: {
        scales: {
          x: {
            type: "time",
            time: {
              unit: "week",
              isoWeekday: true,
              displayFormats: {
                week: "D MMM"
              }
            },
            ticks: {
              source: "data"
            }
          },
          y: {
            title: {
              display: true,
              text: "Story Points"
            }
          }
        }
      },
      loaded: false
    };
  },
  setup() {
    const {
      estimationChart,
      progressionChart,
      dynamicEstimationChart
    } = useChartMath();

    return {
      estimationChart,
      progressionChart,
      dynamicEstimationChart
    };
  },
  async mounted() {
    this.drawCharts();
    this.loaded = true;
  },
  methods: {
    drawCharts() {
      let [estimationChart, storyPointsExcedent] = this.estimationChart(
        this.burndownchart.tasks,
        this.burndownchart.startDate,
        this.burndownchart.endDate,
        "Estimation initiale",
        "rgb(0, 130, 222)",
        this.burndownchart.sprintDays,
        this.burndownchart.periods
      );
      this.chartData.datasets.push(estimationChart);
      this.$emit("storyPointsExcedent", storyPointsExcedent);
      this.chartData.datasets.push(
        this.progressionChart(
          this.burndownchart.tasks,
          this.burndownchart.startDate,
          this.burndownchart.endDate,
          this.burndownchart.sprintDays,
          "Progression en temps réel",
          "rgb(169, 196, 9)",
          isChronologicallyBetween(
            dateNow,
            this.burndownchart.startDate,
            this.burndownchart.endDate
          )
        )
      );
      this.chartData.datasets.push(
        this.dynamicEstimationChart(
          this.burndownchart.tasks,
          this.burndownchart.startDate,
          this.burndownchart.endDate,
          this.burndownchart.sprintDays,
          "Estimation dynamique",
          "rgb(255, 101, 51)",
          this.burndownchart.periods
        )
      );
    }
  }
};
</script>
