<template>
  <VContainer>
    <VRow>
      <VSpacer cols="1" />
      <VCol cols="4">
        <Chart
          v-if="displayChart"
          :chartType="chartType"
          :chartData="chartData"
          :chartOptions="chartOptions"
        ></Chart>
        <VSheet class="ma-2">
          {{ $t("averageVelocity") }} : {{ averageVelocity }}
        </VSheet>
      </VCol>
      <VSpacer cols="1" />
      <VCol cols="6">
        <VDataTable :headers="headers" :items="stats" hide-default-footer>
        </VDataTable>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import Chart from "@/components/Chart.vue";
export default {
  name: "VelocityBoard",
  components: {
    Chart
  },
  props: {
    tasks: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    }
  },
  data: vm => ({
    headers: [
      {
        text: vm.$t("assignee"),
        value: "assignee"
      },
      {
        text: vm.$t("initialStoryPoints"),
        value: "initialStoryPoints"
      },
      {
        text: vm.$t("doneStoryPoints"),
        value: "doneStoryPoints"
      },
      {
        text: vm.$t("impediments"),
        value: "impediments"
      }
    ],
    stats: [],
    displayChart: false,
    chartType: "pie",
    chartData: {},
    chartOptions: {},
    chartColors: [
      "rgb(0, 172, 232)",
      "rgb(252, 185, 2)",
      "rgb(255, 75, 107)",
      "rgb(255, 101, 51)",
      "rgb(66, 200, 190)",
      "rgb(169, 196, 9)",
      "rgb(104, 86, 160)"
    ],
    averageVelocity: 0
  }),
  mounted() {
    this.stats = this.computeVelocity();
    this.averageVelocity = this.computeAverageVelocity();
    this.computePieChart();
  },
  computed: {},
  methods: {
    computeVelocity() {
      let personnalStats = [];
      this.tasks.forEach(task => {
        if (!task.assignee || task.completedFromStartOfSprint) {
          return;
        }
        const assigneePosition = personnalStats.findIndex(
          stats => stats.assignee === task.assignee.name
        );
        const isImpediment =
          task.tags.findIndex(tag => tag.name === "Impediment") != -1;
        if (assigneePosition === -1) {
          if (isImpediment) {
            personnalStats.push({
              assignee: task.assignee.name,
              initialStoryPoints: 0,
              doneStoryPoints: 0,
              impediments: task.consommation ?? 0
            });
          } else {
            personnalStats.push({
              assignee: task.assignee.name,
              initialStoryPoints: task.storypoint,
              doneStoryPoints:
                task.completed || task.sprintComplete ? task.storypoint : 0,
              impediments: 0
            });
          }
        } else {
          if (isImpediment) {
            personnalStats[assigneePosition].impediments +=
              task.consommation ?? 0;
          }
          personnalStats[assigneePosition].initialStoryPoints +=
            task.storypoint;
          personnalStats[assigneePosition].doneStoryPoints +=
            task.completed || task.sprintComplete ? task.storypoint : 0;
        }
      });
      personnalStats = personnalStats.filter(
        stat => stat.initialStoryPoints !== 0 || stat.doneStoryPoints !== 0
      );
      return personnalStats;
    },
    computePieChart() {
      let labels = [];
      let data = [];
      this.stats.forEach(stat => {
        labels.push(stat.assignee);
        data.push(stat.doneStoryPoints);
      });
      this.chartData.labels = labels;
      this.chartData.datasets = [
        {
          data: data,
          backgroundColor: this.chartColors
        }
      ];
      this.displayChart = true;
    },
    computeAverageVelocity() {
      const totalDoneStoryPoints = this.stats.reduce(
        (acc, stat) => acc + stat.doneStoryPoints,
        0
      );
      return Math.round(totalDoneStoryPoints / this.stats.length);
    }
  }
};
</script>
<i18n>
{
  "fr": {
    "averageVelocity": "Vélocité moyenne",
    "assignee": "Nom",
    "initialStoryPoints" : "SP Initiaux",
    "doneStoryPoints" : "SP Faits",
    "impediments" : "Impediments"
  },
  "en": {
    "averageVelocity": "Average velocity",
    "assignee": "Assignee",
    "initialStoryPoints" : "Initial SP",
    "doneStoryPoints" : "Done SP",
    "impediments" : "Impediments"
  }
}
</i18n>
