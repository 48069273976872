import asanaClient from "./asanaclient";
import dayjs from "dayjs";

const storypoint_gid = process.env.VUE_APP_SOCIO_STORYPOINT_GID;
const storypoint_initial_gid = process.env.VUE_APP_SOCIO_STORYPOINT_INITIAL_GID;
const forced_creation_date_gid =
  process.env.VUE_APP_SOCIO_FORCED_CREATION_DATE_GID;
const prio_gid = process.env.VUE_APP_SOCIO_PRIO_GID;
const high_estim_gid = process.env.VUE_APP_SOCIO_HIGH_ESTIM_GID;
const low_estim_gid = process.env.VUE_APP_SOCIO_LOW_ESTIM_GID;
const decommission_date_gid = process.env.VUE_APP_SOCIO_DATE_DECOM_GID;
const consommation_gid = process.env.VUE_APP_SOCIO_CONSOMMATION_GID;
class SocioAsanaClient {
  // Using a wrapper for Asana API calls to help deal
  // with pagination and reformatting of data

  async getTask(projectId) {
    const response = await asanaClient.tasks.getTasksForProject(projectId, {
      limit: 100,
      opt_fields: [
        "this.created_at",
        "this.completed",
        "this.completed_at",
        "this.custom_fields.name",
        "this.custom_fields.number_value",
        "this.custom_fields.text_value",
        "this.custom_fields.enum_value",
        "this.memberships.section.name",
        "this.memberships.section.project.name",
        "this.assignee.name",
        "this.tags.name"
      ]
      // AC Uncomment this line when developping to get easier to read data. Leave commented when you commit for better performances
      // opt_pretty: true
    });
    let formatted_tasks = [];
    let page = response;
    //ASANA's API only delivers one page at a time.
    // So we need to wait for a page to get the next one
    do {
      const formatted_response = page.data.map(task => {
        return this.format_task(task);
      });
      formatted_tasks = formatted_tasks.concat(formatted_response);
      page = page._response.next_page ? await page.nextPage() : null;
    } while (page);
    return formatted_tasks;
  }

  format_task(task) {
    const storypoint = task.custom_fields.find(
      custom_field => custom_field.gid == storypoint_gid
    );
    task["storypoint"] = storypoint.number_value ? storypoint.number_value : 0;
    const storypoint_initial = task.custom_fields.find(
      custom_field => custom_field.gid == storypoint_initial_gid
    );
    task["storypoint_inital"] =
      storypoint_initial && storypoint_initial.number_value
        ? storypoint_initial.number_value
        : 0;
    const forced_creation_date = task.custom_fields.find(
      custom_field => custom_field.gid == forced_creation_date_gid
    );
    if (forced_creation_date && forced_creation_date.text_value) {
      task["created_at"] = dayjs(
        forced_creation_date.text_value,
        "DD/MM/YYYY"
      ).format();
    }
    const prio = task.custom_fields.find(
      custom_field => custom_field.gid == prio_gid
    );
    task["prio"] = prio && prio.enum_value ? prio.enum_value.name : "";
    const high_estimation = task.custom_fields.find(
      custom_field => custom_field.gid == high_estim_gid
    );
    task["high_estimation"] =
      high_estimation && high_estimation.number_value
        ? high_estimation.number_value
        : 0;
    const low_estimation = task.custom_fields.find(
      custom_field => custom_field.gid == low_estim_gid
    );
    task["low_estimation"] =
      low_estimation && low_estimation.number_value
        ? low_estimation.number_value
        : 0;
    const decommission_date = task.custom_fields.find(
      custom_field => custom_field.gid == decommission_date_gid
    );
    task["decommission_date"] =
      decommission_date && decommission_date.text_value
        ? dayjs(decommission_date.text_value, "DD/MM/YYYY").format()
        : null;

    const consommation = task.custom_fields.find(
      custom_field => custom_field.gid == consommation_gid
    );
    task["consommation"] =
      consommation && consommation.number_value
        ? consommation.number_value
        : null;
    return task;
  }
}

const socioAsanaClient = new SocioAsanaClient();

export default socioAsanaClient;
