<template>
  <VDialog v-model="dialog" persistent max-width="600px">
    <VCard>
      <VCardTitle>
        <span class="headline">{{ $t("dialogTitle") }}</span>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <VRow>
            <VCol cols="12" sm="6">
              <VTextField
                v-model="backlog.name"
                disabled
                :label="$t('project')"
              ></VTextField>
              <VSelect
                :items="sprint_length"
                item-text="name"
                item-value="days"
                v-model="sprintDays"
                :label="$t('sprint_length')"
                required
              ></VSelect>
            </VCol>
            <VCol cols="12" sm="6">
              <VTextField
                v-model="backlog.start_on"
                :label="$t('startDate')"
                prepend-icon="mdi-calendar"
                disabled
              ></VTextField>
              <VTextField
                v-model="backlog.due_on"
                :label="$t('endDate')"
                prepend-icon="mdi-calendar"
                disabled
              ></VTextField>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VSpacer></VSpacer>
        <VBtn color="blue darken-1" text @click="$emit('closedialog')">
          {{ $t("close") }}
        </VBtn>
        <VBtn color="blue darken-1" text @click="recordData">
          {{ $t("create") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
<script>
export default {
  name: "BurndownChartDialog",
  props: {
    backlog: {
      type: Object,
      required: true,
      default: () => {
        return {
          gid: "",
          start_on: "",
          due_on: "",
          name: ""
        };
      }
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    sprint_length: [
      {
        name: "Une semaine",
        days: 7
      },
      {
        name: "Deux semaines",
        days: 14
      },
      {
        name: "Trois semaines",
        days: 21
      }
    ],
    sprintDays: 7
  }),
  methods: {
    recordData() {
      this.$emit("recordData", this.sprintDays);
    }
  }
};
</script>
<i18n>
{
  "fr": {
    "dialogTitle": "Enregistrer un backlog",
    "project" : "Projet",
    "sprint_length": "Durée d'un sprint",
    "startDate" : "Date de début",
    "endDate" : "Date de fin",
    "priority" : "Priorité",
    "close" : "Annuler",
    "create" : "Générer"
  },
  "en": {
    "dialogTitle": "Record a backlog",
    "project" : "Project",
    "sprint_length": "Sprint length",
    "startDate" : "Start Date",
    "endDate" : "End Date",
    "priority" : "Priority",
    "close" : "Close",
    "create" : "Create"
  }
}
</i18n>
